import { template as template_503bf21b943042a494c201801c8a2a25 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_503bf21b943042a494c201801c8a2a25(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
