import { template as template_bf2f98bc58344cc7a29cb048c10474ca } from "@ember/template-compiler";
const FKControlMenuContainer = template_bf2f98bc58344cc7a29cb048c10474ca(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
