import { template as template_d7c3a403ac6149b4a9cc514d6e855a63 } from "@ember/template-compiler";
const FKText = template_d7c3a403ac6149b4a9cc514d6e855a63(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
